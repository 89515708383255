import React, { useEffect, useMemo, useRef, useState, useCallback } from "react";
import {
  useFormFieldState,
  useFormErrorSetter,
} from "theme/components/atoms/Form/Form/FormComponent";
import useDebounce from "theme/components/helpers/useDebounce";
// import useLoadSmartInputSuggestionsEffect from "./useLoadSmartInputSuggestionsEffect";
import useSmartInputCallbacks from "./useSmartInputCallbacks";
import useSmartInputAutoComplete from "./useSmartInputAutoComplete";
import makeSmartInputAutoCompleteDisabledHook from "./makeSmartInputAutoCompleteDisabledHook";
import { useIntl } from "react-intl";
import { useFormData } from "theme/components/atoms/Form/Form/Form";
import { useFormDataSetter } from "theme/components/atoms/Form/Form/FormComponent";
import useDetectAutoComplete from "../helpers/useDetectAutoComplete";
import useMultipleSetRefs from "../helpers/useMultipleSetRefs";
import useDelayedQuery from "../helpers/useDelayedQuery";
import useLazyQueryAlwaysTriggeredOnComplete from "../helpers/useLazyQueryAlwaysTriggeredOnComplete";

const DEFAULT_INPUT_DEBOUNCE_DURATION = 200;

/**
 * @typedef {Object} Props
 */

/**
 * @typedef {Object} LocalProps
 */

/**
 * @typedef {Object} Suggestions
 */

/**
 * @typedef {Object} OnSuggestionSelectedOptions
 * @property {LocalProps} localProps - the local props that are fetched from usePropsSplitter
 * @property {String} fieldName - the name of the current field
 * @property {any} suggestionValue - the value of the suggestion that was selected
 * @property {Function} setValue - a setter to set the value of the form field (accepts function that will be send the old value)
 * @property {Function} setFormData - a setter to set the whole form data (accepts function that will be send the old value)
 * @property {Function} setFocused - a setter to change the focus of the field (set false to hide suggestions)
 * @property {Suggestions} suggestions - the suggestions extracted from GraphQL data (using graphQLField)
 */

/**
 * A function that is called when a suggestion is selected
 * @callback OnSuggestionSelectedCallback
 * @param {OnSuggestionSelectedOptions} onSuggestionSelectedOptions
 */

/**
 * A function given the field value and the suggestions data can optionally hide the suggestions.
 * To be used for example to check if the current field value exactly matches one of the suggestions
 * and if so hide the suggestion by returning true.
 * @callback UseHideSuggestionsHook
 * @param {any} value - the value of the current field
 * @param {Suggestions} [suggestions] - the suggestions extracted from GraphQL data (using graphQLField)
 * @param {Boolean} [alwaysShowSuggestions] - If the suggestion list is allways open if there is suggestions
 * @returns {Boolean} - if true will hide the suggestions
 */

/**
 * A function given the suggestions data must return suggestions.
 * To be used to force user to choose a value in the suggestions.
 * @callback UseSuggestionValueHook
 * @param {Suggestions} [suggestions] - the suggestions extracted from GraphQL data (using graphQLField)
 * @returns {Array} - Array of suggestions values
 */

/**
 * A function splits the props into local props (to be used internally by the smart field) and base props to be propagated to base component
 * @callback UsePropsSplitterHook
 * @param {Props} props
 * @returns {[LocalProps, Props]} - an tuple [localProps, baseProps] only base props will be sent to child while local props will be sent to other hooks/functions
 */

/**
 * a function to get the GraphQL query variables (make it memoised to avoid performance regressions)
 * @callback UseQueryVariablesHook
 * @param {any} value - the value of the current field
 * @param {Object} formData - the form data should you need it (like in <City/> you might need the selected country)
 * @param {LocalProps} localProps - the local props that are fetched from usePropsSplitter
 * @returns {Object} - the query variables to send to GraphQL
 */

/**
 * @typedef {Object} RenderSuggestionsCallbackOptions
 * @property {Suggestions} suggestions - the suggestions extracted from GraphQL data (using graphQLField)
 * @property {string} selected - the id of the selected option
 * @property {String} fieldName - the name of the current field
 * @property {Function} onSelect - a function to set the state on an option to selected (like hovered not actually selecting it)
 * @property {Function} onSuggestionSelected - a function to call to select an option (like actually select it not just like hover over it)
 */

/**
 * a function render suggestions (should use <AutocompleteResults> and <AutocompleteOption> organisms)
 * @callback RenderSuggestionsCallback
 * @param {RenderSuggestionsCallbackOptions} renderOptions
 * @returns the rendered component use <AutocompleteResults> and <AutocompleteOption> organisms
 */

/**
 * a function that return a message depending the ID param
 * @callback GetErrorMessageCallback
 * @param {String} Error Message id
 * @returns the message in react-intl format, must use intl to translate it
 */

/**
 * @callback ValidationRulesCallback
 * @param {Object} values all form values
 * @param {any} value the current field's value
 * @returns boolean value
 */

/**
 * @typedef {Object} WithSmartInputProps
 * @property {any} Query - GraphQL query
 * @property {String} graphQLField - the GraphQL data field name to find suggestions on
 * @property {Number} debounceDuration - the amount of time to wait before issuing a request to get suggestions
 * @property {OnSuggestionSelectedCallback} [onSuggestionSelected] - a callback to handle suggestion selection (should do some action [set form field value]). Defaults to just set the form field value
 * @property {UseHideSuggestionsHook} [useHideSuggestions] - a hook to check if the auto complete options should be hidden. Defaults to nerver hiding
 * @property {UseSuggestionValueHook} [UseSuggestionValue] - a hook to check if the value is one of the suggestion list
 * @property {UseAutoCompleteDisabledHook} [useAutoCompleteDisabled] - a hook to check if the autocompletion should be disabled base on error. Defaults to checking error message with "Not implemented" and is globally sticky (i.e. one such error will disable all autocompletion globally)
 * @property {UsePropsSplitterHook} [usePropsSplitter] - a hook to split the props between local props (not sent to base component), and baseProps (sent to base components). Defaults to all base props and no local props
 * @property {UseQueryVariablesHook} useQueryVariables - a hook to get the query variables to send to GraphQl
 * @property {any} [defaultValue] - the default value of the field. Defaults to ""
 * @property {RenderSuggestionsCallback} renderSuggestions - renderer to display the suggestions (should use <AutocompleteResults> and <AutocompleteOption> organisms)
 * @property {GetErrorMessageCallback} getErrorMessage - return intlFormat message
 * @property {ValidationRulesCallback} validationsRules - return the validation rule
 */

/** @type {OnSuggestionSelectedCallback} */
const defaultOnSuggestionSelected = ({ setValue, suggestionValue }) =>
  setValue(suggestionValue);

/**
 * a factory to create smart form fields
 * @param {WithSmartInputProps} param0
 * @returns {Function} an HOC function to be used to wrap a form component
 */
const withSmartInput = ({
  Query,
  refetchSuggestionsOnAutocomplete = false,
  onSuggestionsLoaded = () => {},
  graphQLField,
  debounceDuration = DEFAULT_INPUT_DEBOUNCE_DURATION,
  onSuggestionSelected: onBaseSuggestionSelected = defaultOnSuggestionSelected,
  useHideSuggestions = () => false,
  useSuggestionsValue = () => false,
  useAutoCompleteDisabled,
  usePropsSplitter = (props) => [null, props],
  useQueryVariables,
  defaultValue = "",
  renderSuggestions,
  getErrorMessage,
  validationsRules,
}) => {
  useAutoCompleteDisabled =
    useAutoCompleteDisabled || makeSmartInputAutoCompleteDisabledHook();
  return (BaseInputComponent) => (props) => {
    let emitSuggestionsLoaded;
    const intl = useIntl();
    const [loadSuggestions, { error, data }] =
      useLazyQueryAlwaysTriggeredOnComplete(Query, {
        onCompleted: (data) => emitSuggestionsLoaded(data),
      });

    const suggestions = data?.[graphQLField];
    const disabled = useAutoCompleteDisabled(error, suggestions);

    const [
      localProps,
      {
        mustBeSuggestion = false,
        alwaysShowSuggestions = false,
        fieldName = props.name,
        ...baseProps
      },
    ] = usePropsSplitter(props);

    const [focused, setFocused] = useState(false);

    const [value, setValue] = useFormFieldState(
      fieldName,
      props.value ?? defaultValue
    );
    const formErrorSetter = useFormErrorSetter();

    const valueStr = JSON.stringify(value);
    const debouncedValueStr = useDebounce(valueStr, debounceDuration);
    const debouncedValue = useMemo(
      () => JSON.parse(debouncedValueStr),
      [debouncedValueStr]
    );

    const [autoCompleted, setAutoCompleteRef] =
      useDetectAutoComplete(debouncedValue);
    const [contentRef, setRef] = useMultipleSetRefs(
      setAutoCompleteRef,
      baseProps.baseInputProps?.setRef
    );

    const formData = useFormData();
    const queryVariables = useQueryVariables(
      debouncedValue,
      formData,
      localProps
    );

    const hideSuggestions = useHideSuggestions(debouncedValue, suggestions, alwaysShowSuggestions);
    const suggestValues = mustBeSuggestion && useSuggestionsValue(suggestions);
    const isSuggestionsOpen = !disabled && !hideSuggestions && focused;


    /**
     *  Check if the input value is one of the suggested values.
     * @type {(function(): void)|*}
     */
    const doCheckMustBeSuggestion = useCallback(() => {
      if (!disabled && mustBeSuggestion && suggestValues) {
        if (!suggestValues?.includes(value)) {
          formErrorSetter({
            [fieldName]: [
              intl.formatMessage(
                getErrorMessage("MUST_BE_SUGGESTION")
              )
            ],
          });
        }
      }
    }, [
      disabled,
      mustBeSuggestion,
      value,
      suggestValues,
      fieldName,
      intl,
      formErrorSetter
    ]);

    const prev = useRef({}).current;
    const shouldCheck = (mustBeSuggestion || prev.focused) && !focused;
    prev.focused = focused;

    useEffect(() => {
      if (shouldCheck) {
        doCheckMustBeSuggestion();
      }
    }, [shouldCheck, doCheckMustBeSuggestion]);

    const { onFocus, onChange, onBlur, onClose, onSuggestionSelected } =
      useSmartInputCallbacks(
        localProps,
        baseProps,
        setValue,
        setFocused,
        contentRef,
        onBaseSuggestionSelected,
        suggestions
      );

    const setFormData = useFormDataSetter();
    emitSuggestionsLoaded = useCallback(
      (data) => {
        onSuggestionsLoaded({
          localProps,
          fieldName,
          setValue,
          setFormData,
          setFocused,
          suggestions: data?.[graphQLField],
          autoCompleted,
        });
      },
      [localProps, fieldName, setValue, setFormData, setFocused, autoCompleted]
    );

    const runLoad =
      (focused && !autoCompleted) || // load only when focused
      (refetchSuggestionsOnAutocomplete && autoCompleted); // conditionally run on autocomplete

    useDelayedQuery(disabled, queryVariables, runLoad, loadSuggestions);

    useEffect(() => {
      onChange();
    }, [onChange, valueStr]);

    const [autocompleteProps, selected, onSelect] = useSmartInputAutoComplete(
      contentRef,
      isSuggestionsOpen,
      debouncedValue,
      onClose,
      onSuggestionSelected
    );

    return (
      <BaseInputComponent
        {...baseProps}
        {...autocompleteProps}
        baseInputProps={{ ...props.baseInputProps, setRef }}
        value={value}
        onFocus={onFocus}
        onBlur={onBlur}
        sugg={suggestions}
        suggestions={
          isSuggestionsOpen
            ? renderSuggestions({
              value,
              suggestions,
              selected,
              onSelect,
              onSuggestionSelected,
            })
            : null
        }
        validations={ validationsRules && {
          smartInputCustomRule: validationsRules,
        }}
        validationErrors={ validationsRules && {
          smartInputCustomRule: intl.formatMessage(
            getErrorMessage("MUST_BE_VALID")
          ),
        }}
      />
    );
  };
};

export default withSmartInput;
